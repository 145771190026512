/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TaskGroupModel } from './TaskGroupModel';
import type { UserModel } from './UserModel';

export type TaskGroupChangeLogModel = {
    createdAt: string;
    updatedAt: string;
    property: TaskGroupChangeLogModel.property;
    oldValue: string | null;
    newValue: string | null;
    taskGroupId: string;
    userId: string;
    taskGroup: TaskGroupModel | null;
    user: UserModel | null;
    id: string;
};

export namespace TaskGroupChangeLogModel {

    export enum property {
        CLINIC = 'clinic',
        PET = 'pet',
        CUSTOMER = 'customer',
        ASSIGNEE = 'assignee',
        STATUS = 'status',
        DATE = 'date',
        DESCRIPTION = 'description',
        TITLE = 'title',
        GROUP = 'group',
    }


}

