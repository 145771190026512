import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Grid } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, TFuncKey } from 'react-i18next';

import { getKeysOfParentNodes } from './utils';
import { MenuItem, roles } from './MenuData';
import styles from './AppMenu.module.scss';
import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { joinPathnames } from './utils/joinPathnames';

const { useBreakpoint } = Grid;
const { SubMenu } = Menu;
const { Sider } = Layout;

interface AppMenuProps {
  menuData: MenuItem[];
  menuCollapsed: boolean;
  closeMenu: () => void;
  openMenu: () => void;
}

const AppMenu = ({
  menuData,
  menuCollapsed,
  closeMenu,
  openMenu,
}: AppMenuProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const determineMenuStateFromURL = useCallback(() => {
    const parsedUrl = location?.pathname.split('/');

    if (!parsedUrl || location?.pathname === '/') {
      setOpenedItems([]);
      setSelectedKey('home');

      return;
    }

    if (parsedUrl[1] === 'm' && parsedUrl.length === 3) {
      const nodeParentKeys = getKeysOfParentNodes(menuData, parsedUrl[2]) || [];

      setOpenedItems(nodeParentKeys);
      setSelectedKey(parsedUrl[2]);

      return;
    }

    setOpenedItems([]);
    setSelectedKey(null);
  }, [location?.pathname, menuData]);

  useEffect(() => {
    const isMobile = screens.sm !== undefined && !screens.sm;

    if (isMobile && !menuCollapsed) {
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens, closeMenu]);

  useEffect(() => {
    determineMenuStateFromURL();
  }, [determineMenuStateFromURL]);

  const isRouteChildActive = useCallback(
    (menuItems: MenuItem[] = []) => {
      return joinPathnames(menuItems).includes(location.pathname)
        ? 'true'
        : undefined;
    },
    [location.pathname],
  );

  const renderMenu = (menuData: MenuItem[]) => {
    const currentRole = localStorage.role;

    return menuData.map((c) => {
      if (
        c.hidden ||
        (c.requiresPermission && currentRole !== c.requiresPermission)
      ) {
        return null;
      }

      if (c.children) {
        return (
          <SubMenu
            key={c.key}
            title={
              <>
                {c.icon}
                <span>{t(c.name as TFuncKey)}</span>
              </>
            }
            className={
              menuCollapsed && isRouteChildActive(c.children)
                ? styles.activeSubMenu
                : undefined
            }>
            {renderMenu(c.children)}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={c.key}>
          <Link to={`${c.path}`}>
            {c.icon}
            <span>{t(c.name as TFuncKey)}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  const isGod = localStorage.role === roles.God;

  if (!isGod) {
    return <Sider collapsedWidth={0} trigger={null} width={0} />;
  }

  return (
    <Sider
      collapsedWidth={88}
      trigger={null}
      collapsed={menuCollapsed}
      width={260}
      className={styles.sider}>
      <div className={styles.menuControl}>
        {menuCollapsed ? (
          <MenuUnfoldOutlined
            style={{
              fontSize: 18,
              padding: '0 24px',
            }}
            onClick={openMenu}
          />
        ) : (
          <MenuFoldOutlined
            style={{
              fontSize: 18,
              padding: '0 24px',
            }}
            onClick={closeMenu}
          />
        )}
      </div>
      <Menu
        theme="dark"
        selectedKeys={[selectedKey || '']}
        // openKeys={[...openedItems]}
        onOpenChange={(p) => {
          setOpenedItems(p);
        }}
        mode="inline"
        style={{ borderRight: 0 }}
        expandIcon={
          <div>
            <ArrowIcon style={{ minWidth: 13, minHeight: 7 }} />
          </div>
        }
        className={styles.appMenu}>
        {renderMenu(menuData)}
      </Menu>
    </Sider>
  );
};

export default AppMenu;
