/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAvailabilityDto } from '../models/CreateAvailabilityDto';
import type { CreateAvailabilityGroupDto } from '../models/CreateAvailabilityGroupDto';
import type { CreateAvailabilityRo } from '../models/CreateAvailabilityRo';
import type { DeleteAvailabilityDto } from '../models/DeleteAvailabilityDto';
import type { DeleteAvailabilityGroupDto } from '../models/DeleteAvailabilityGroupDto';
import type { GetClinicAvailableDaysDto } from '../models/GetClinicAvailableDaysDto';
import type { GetClinicAvailableDaysRo } from '../models/GetClinicAvailableDaysRo';
import type { GetClinicWorkingDaysDto } from '../models/GetClinicWorkingDaysDto';
import type { GetClinicWorkingDaysRo } from '../models/GetClinicWorkingDaysRo';
import type { GetDoctorAvailableDurationsDto } from '../models/GetDoctorAvailableDurationsDto';
import type { GetDoctorAvailableDurationsRo } from '../models/GetDoctorAvailableDurationsRo';
import type { GetDoctorWorkingDaysDto } from '../models/GetDoctorWorkingDaysDto';
import type { UpdateAvailabilityDto } from '../models/UpdateAvailabilityDto';
import type { UpdateAvailabilityGroupDto } from '../models/UpdateAvailabilityGroupDto';
import type { WorkingHourGroupModel } from '../models/WorkingHourGroupModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AvailabilityAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CreateAvailabilityRo
     * @throws ApiError
     */
    public availabilityAdminControllerCreate({
        requestBody,
    }: {
        requestBody: CreateAvailabilityDto,
    }): CancelablePromise<CreateAvailabilityRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CreateAvailabilityRo
     * @throws ApiError
     */
    public availabilityAdminControllerUpdate({
        requestBody,
    }: {
        requestBody: UpdateAvailabilityDto,
    }): CancelablePromise<CreateAvailabilityRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public availabilityAdminControllerDelete({
        requestBody,
    }: {
        requestBody: DeleteAvailabilityDto,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WorkingHourGroupModel
     * @throws ApiError
     */
    public availabilityAdminControllerCreateRecurring({
        requestBody,
    }: {
        requestBody: CreateAvailabilityGroupDto,
    }): CancelablePromise<WorkingHourGroupModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/create-recurring',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WorkingHourGroupModel
     * @throws ApiError
     */
    public availabilityAdminControllerUpdateRecurring({
        requestBody,
    }: {
        requestBody: UpdateAvailabilityGroupDto,
    }): CancelablePromise<WorkingHourGroupModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/update-recurring',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WorkingHourGroupModel
     * @throws ApiError
     */
    public availabilityAdminControllerDeleteRecurring({
        requestBody,
    }: {
        requestBody: DeleteAvailabilityGroupDto,
    }): CancelablePromise<WorkingHourGroupModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/delete-recurring',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetClinicWorkingDaysRo
     * @throws ApiError
     */
    public availabilityAdminControllerGetClinicWorkingDays({
        requestBody,
    }: {
        requestBody: GetClinicWorkingDaysDto,
    }): CancelablePromise<GetClinicWorkingDaysRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/clinic/working-days',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetClinicAvailableDaysRo
     * @throws ApiError
     */
    public availabilityAdminControllerGetClinicAvailableDays({
        requestBody,
    }: {
        requestBody: GetClinicAvailableDaysDto,
    }): CancelablePromise<GetClinicAvailableDaysRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/clinic/available-days',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public availabilityAdminControllerGetDoctorWorkingDays({
        requestBody,
    }: {
        requestBody: GetDoctorWorkingDaysDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/doctor/working-days',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetDoctorAvailableDurationsRo
     * @throws ApiError
     */
    public availabilityAdminControllerGetDoctorAvailableDurations({
        requestBody,
    }: {
        requestBody: GetDoctorAvailableDurationsDto,
    }): CancelablePromise<GetDoctorAvailableDurationsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/availability/doctor/available-durations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
