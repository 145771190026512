import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal } from 'antd';
import styles from './GenericModal.module.scss';
import { Trans } from 'react-i18next';
import { Button } from 'components/Button';
import { ReactComponent as WarningIcon } from './assets/warning-icon.svg';
import { ReactComponent as CancelIcon } from './assets/cancel-icon.svg';
import classNames from 'classnames';

export type GenericModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  onConfirm?: () => Promise<void> | void;
  onDecline?: () => Promise<void> | void;
  onThirdButton?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  loading?: boolean;
  title: string;
  description?: string;
  declineButtonText: string;
  confirmButtonText: string;
  thirdButtonText?: string;
  declineOnCancel?: boolean;
  buttonsOnDifferentLines?: boolean;
  children?: ReactNode;
  forcedSameDisplay?: boolean;
};

export const GenericModal: React.FC<GenericModalProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onDecline,
  onThirdButton,
  onCancel,
  loading,
  title,
  description,
  declineButtonText,
  confirmButtonText,
  thirdButtonText,
  declineOnCancel,
  buttonsOnDifferentLines,
  forcedSameDisplay: _forcedSameDisplay,
  children,
}) => {
  const forcedSameDisplay =
    _forcedSameDisplay || (thirdButtonText && onThirdButton);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);

  const buttonsDisabled = useMemo(() => {
    return loading || internalLoading;
  }, [internalLoading, loading]);

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.removeEventListener('keydown', keyDownHandler);
        handleConfirm();
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', keyDownHandler);
    } else {
      document.removeEventListener('keydown', keyDownHandler);
    }

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = useCallback(async () => {
    if (declineOnCancel && onDecline) {
      await onDecline();
    } else if (onCancel) {
      onCancel();
    }
    setIsOpen(false);
  }, [declineOnCancel, onCancel, onDecline, setIsOpen]);

  const handleDecline = useCallback(async () => {
    try {
      setInternalLoading(true);

      if (onDecline) {
        await onDecline();
      }
      setIsOpen(false);
      setInternalLoading(false);
    } catch (error) {}
  }, [onDecline, setIsOpen]);

  const handleConfirm = useCallback(async () => {
    try {
      setInternalLoading(true);

      if (onConfirm) {
        await onConfirm();
      }
      setIsOpen(false);
      setInternalLoading(false);
    } catch (error) {}
  }, [onConfirm, setIsOpen]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      className={styles.container}
      onCancel={(e) => {
        e.stopPropagation();
        handleCancel();
      }}
      centered>
      <div
        className={styles.contentContainer}
        onClick={(e) => e.stopPropagation()}>
        <div className={styles.topIconContainer}>
          <WarningIcon />
        </div>
        <button
          className={styles.cancelIconContainer}
          disabled={buttonsDisabled}
          onClick={handleCancel}>
          <CancelIcon />
        </button>
        <div className={styles.innerContent}>
          <span className={styles.title}>
            <Trans
              i18nKey={title as any}
              components={{
                HIGHLIGHT: <span className={styles.hightlight} />,
                BR: <br />,
              }}
            />
          </span>
          {children}
          {description && (
            <span className={styles.description}>{description}</span>
          )}
          <div
            className={classNames(
              styles.controls,
              buttonsOnDifferentLines ? styles.controlsOnDiffLines : null,
              buttonsOnDifferentLines && thirdButtonText && onThirdButton
                ? styles.controlsOnDiffLinesWithThird
                : null,
            )}>
            <Button
              disabled={buttonsDisabled}
              type={forcedSameDisplay ? 'secondary' : 'tertiary'}
              onClick={handleDecline}>
              {declineButtonText}
            </Button>
            <Button
              type={forcedSameDisplay ? 'secondary' : 'primary'}
              disabled={buttonsDisabled}
              onClick={handleConfirm}>
              {confirmButtonText}
            </Button>
            {thirdButtonText && onThirdButton ? (
              <Button
                type="secondary"
                disabled={buttonsDisabled}
                onClick={onThirdButton}>
                {thirdButtonText}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};
