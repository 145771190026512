/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TaskGroupChangeLogModel } from './TaskGroupChangeLogModel';
import type { TaskModel } from './TaskModel';

export type TaskGroupModel = {
    createdAt: string;
    updatedAt: string;
    recurrenceType: TaskGroupModel.recurrenceType;
    recurrenceInterval: number;
    startDate: string | null;
    endDate: string | null;
    additionalOptions?: any;
    tasks: Array<TaskModel>;
    changeLogs: Array<TaskGroupChangeLogModel>;
    id: string;
};

export namespace TaskGroupModel {

    export enum recurrenceType {
        DAILY = 'daily',
        WEEKLY = 'weekly',
        MONTHLY = 'monthly',
    }


}

