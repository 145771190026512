import React, { useState } from 'react';
import { GenericModal, GenericModalProps } from './components/GenericModal';
import { useTranslation } from 'react-i18next';

export type ModalProps = {
  onConfirm?: GenericModalProps['onConfirm'];
  onDecline?: GenericModalProps['onDecline'];
  onThirdButton?: GenericModalProps['onThirdButton'];
  onCancel?: GenericModalProps['onCancel'];
  loading?: GenericModalProps['loading'];
  title: GenericModalProps['title'];
  description?: GenericModalProps['description'];
  declineButtonText?: GenericModalProps['declineButtonText'];
  confirmButtonText?: GenericModalProps['confirmButtonText'];
  thirdButtonText?: GenericModalProps['thirdButtonText'];
  declineOnCancel?: GenericModalProps['declineOnCancel'];
  buttonsOnDifferentLines?: boolean;
  forcedSameDisplay?: boolean;
};

export const useModal = () => {
  const [modalProps, setModalProps] = useState<ModalProps | null>();
  const { t } = useTranslation();

  return {
    modal: modalProps ? (
      <GenericModal
        isOpen={true}
        setIsOpen={(val) => {
          if (val === false) {
            setModalProps(null);
          }
        }}
        title={modalProps.title}
        description={modalProps.description}
        declineButtonText={modalProps.declineButtonText || t('cancel')}
        confirmButtonText={modalProps.confirmButtonText || t('yes')}
        thirdButtonText={modalProps.thirdButtonText}
        loading={modalProps.loading}
        onConfirm={modalProps.onConfirm}
        onDecline={modalProps.onDecline}
        onThirdButton={modalProps.onThirdButton}
        declineOnCancel={modalProps.declineOnCancel}
        buttonsOnDifferentLines={modalProps.buttonsOnDifferentLines}
        forcedSameDisplay={modalProps.forcedSameDisplay}
        onCancel={modalProps.onCancel}
      />
    ) : null,
    openModal: (props: ModalProps) => setModalProps(props),
  };
};
