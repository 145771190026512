/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RecurringAdditionalOptionsDto } from './RecurringAdditionalOptionsDto';

export type RecurringTaskOptions = {
    recurrenceType: RecurringTaskOptions.recurrenceType;
    recurrenceInterval: number;
    startDate: string;
    endDate?: string;
    additionalOptions?: RecurringAdditionalOptionsDto;
};

export namespace RecurringTaskOptions {

    export enum recurrenceType {
        DAILY = 'daily',
        WEEKLY = 'weekly',
        MONTHLY = 'monthly',
    }


}

