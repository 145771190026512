/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileModel } from './FileModel';
import type { VisitModel } from './VisitModel';

export type VisitInvestigationModel = {
    createdAt: string;
    updatedAt: string;
    name: string;
    type: VisitInvestigationModel.type;
    date: string;
    status: VisitInvestigationModel.status;
    visitId: string;
    fileId: string;
    ibhId: number;
    visit: VisitModel | null;
    file: FileModel | null;
    isLaboratory: boolean;
    isComplete: boolean;
    isReady: boolean;
    isInternalOnly: boolean;
    id: string;
};

export namespace VisitInvestigationModel {

    export enum type {
        LAB_IDEXX = 'lab_idexx',
        LAB_LABOKLIN = 'lab_laboklin',
        LABORATORY = 'laboratory',
        XRAY = 'xray',
        ULTRASOUND = 'ultrasound',
        ENDOSCOPY = 'endoscopy',
        OTHER = 'other',
        UNKNOWN = 'unknown',
    }

    export enum status {
        PENDING = 'pending',
        READY = 'ready',
        COMPLETED = 'completed',
        COMPLETED_WITH_NO_FILE = 'completed_with_no_file',
        FAILED = 'failed',
    }


}

